<!--  -->
<template>
  <div>
    <div>
      <pNav></pNav>
    </div>
    <div class="content">
      <div class="inner">

<!--        <div class="filter">-->
<!--          <span>筛选条件：</span>-->
<!--          <span class="tag">-->
<!--            人工智能-->
<!--            <i class="iconfont pdxcha"></i>-->
<!--          </span>-->
<!--        </div>-->

        <div class="category">
          <div class="line">
            <div class="lable">API分类</div>
            <div class="val">
              <div
                :class="['item',{'active':current==index}]"
                v-for="(item,index) in api"
                :key="index"
                @click="switchType(index)"
              >{{item.name}}</div>
            </div>
          </div>

<!--          <div class="line">-->
<!--            <div class="lable">接口类型</div>-->
<!--            <div class="val">-->
<!--              <div-->
<!--                :class="['item',{'active':current1==index}]"-->
<!--                v-for="(item,index) in apiType"-->
<!--                :key="index"-->
<!--              >{{item}}</div>-->
<!--            </div>-->
<!--          </div>-->

        </div>
        <div class="paixu">
          <div class="key">
            使用人数
            <span class="arrow">
              <i class="iconfont pdxxia"></i>
            </span>
          </div>
          <div class="key">
            热门
            <span class="arrow">
              <i class="iconfont pdxxia"></i>
            </span>
          </div>
          <div class="key">
            上架时间
            <span class="arrow">
              <i
                class="iconfont pdxxia"
                style="display:inline-block;
            transform: rotate(180deg);"
              ></i>
              <i class="iconfont pdxxia"></i>
            </span>
          </div>
        </div>
        <div class="list">
          <div class="card" v-for="(item,index) in shopList" :key="index" @click="jump('product/info?id='+item.id)">
            <span class="label">自营</span>
            <div class="img layout-flex">
              <img :src="item.imgUrl" alt />
            </div>
            <div class="txt">
              <div class="tit">{{item.name}}</div>
              <p class="overLine2" v-html="item.info"></p>
              <p class="tips">低至0.001元/每次</p>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <pagination layout="total, prev, pager, next" :total="total" :current-page="queryForm.currentPage" :page-size="queryForm.size"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import pNav from "@/components/pc/p-nav/p-nav"
import {getCommodityClassification, getCommodityPage} from "@/api/frontApi";
export default {
  data () {
    return {
      current: 0,
      current1: 0,
      api: [
          {id: "-1",
          name: "全部"}],
      apiType: ["全部", "免费", "第三方"],
      //分页用
      queryForm:{//搜索条件
        currentPage:1,//当前页码
        size:10, //每页条数
        type:'',//所属分类
      },
      total:1,//总条数
      shopList:[],//商品列表
    };
  },

  components: {
    pNav
  },

  computed: {},
    created () {
    //获取商品分类
      getCommodityClassification().then(res => {
        console.log(res)
        res.forEach(item=>{
          this.api.push(item);
        })
      })
      //获取商品列表
      this.getShopPage()
    },
  methods: {
    jump (path) {
      this.$router.push('/' + path)
    },
    //获取商品列表
    getShopPage(){
      getCommodityPage(this.queryForm).then(res => {
        console.log(res)
        this.shopList = res.records
        this.total = res.total
      })
    },
    //切换分类
    switchType(index){
      this.current=index
      //搜索条件
      this.queryForm.type = this.api[index].id
      //如果下标为0  是查看全部。
      if (index == 0 ) {
        this.queryForm.type = ''
      }
      //分页回1
      this.queryForm.currentPage = 1
      //获取商品列表
      this.getShopPage()
    }
  }
}

</script>
<style lang='scss' scoped>
.content {
  padding: 20px 0;
  width: 100%;
  background: #f3f7fa;
  .inner {
    max-width: $minWidth;
    margin: 0 auto;
    .filter {
      margin-bottom: 20px;
      font-size: 14px;
      span {
        margin-left: 10px;
      }
      .tag {
        padding: 6px 8px;
        font-size: 12px;
        cursor: pointer;
        color: #666;
        border: 1px solid #dbdbdb;
        background: #fff;
        i {
          font-size: 12px;
          display: inline-block;
          width: 10px;
          height: 10px;
        }
        &:hover {
          border-color: #b5b5b5;
        }
      }
    }
    .category {
      margin-bottom: 20px;
      background: #fff;
      .line {
        padding: 10px 14px;
        display: flex;
        align-items: center;
        &:nth-of-type(1) {
          border-bottom: 1px solid #efefef;
        }
        .lable {
          font-size: 15px;
          margin-right: 30px;
        }
        .val {
          font-size: 14px;
          display: flex;
          flex-wrap: wrap;
          .item {
            padding: 8px 10px;
            margin-right: 10px;
            color: #666;
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid transparent;
            &:hover {
              background: #f9f9f9;
            }
          }
          .active {
            color: #2468f2;
            border: 1px solid #2468f2;
            &:hover {
              background: #fff;
            }
          }
        }
      }
    }
    .paixu {
      margin-bottom: 20px;
      padding: 15px;
      background: #fff;
      display: flex;
      .key {
        display: flex;
        align-items: center;
        color: #666;
        font-size: 14px;
        margin-right: 20px;
        cursor: pointer;
        &:hover {
          color: #4b9be3;
          // .arrow {
          //   color: #4b9be3;
          // }
        }
        .arrow {
          margin-left: 8px;
          color: #c0c4cc;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 16px;
          i {
            &:nth-of-type(2) {
              margin-top: -8px;
            }
          }
        }
      }
    }
    .list {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      .card {
        margin-bottom: 20px;
        width: calc(20% - 10px);
        padding: 12px 0;
        background: #fff;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        margin: 0 5px 20px;
        // &:not(:nth-of-type(5n)) {
        //   margin-right: 16px;
        // }
        .img {
          margin: 0 auto 15px;
          width: 96px;
          height: 96px;
          background: #f2f5f9;
          border-radius: 50%;
          justify-content: center;
          img {
            width: 57px;
            height: 57px;
          }
        }
        .txt {
          padding: 0 15px;
          text-align: center;
          .tit {
            font-size: 16px;
            margin-bottom: 15px;
          }
          p {
            margin-bottom: 25px;
            font-size: 12px;
            color: #666;
            line-height: 1.5;
          }
          .tips {
            margin-top: 10px;
            font-size: 16px;
            color: #ff6a00;
          }
        }
        .label {
          position: absolute;
          top: 0;
          left: 15px;
          font-size: 12px;
          padding: 4px;
          color: #fff;
          background: #3e8ed0;
        }
        &:hover {
          box-shadow: 1px 5px 10px 3px #efefef;
        }
      }
    }
  }
}
@media screen and(max-width:996px) {
  .content {
    .inner {
      .list {
        .card {
          margin: 0 10px 20px;
          width: calc(50% - 20px);
        }
      }
    }
  }
}
</style>
